@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@300&display=swap);
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

a, a:link, a:visited, a:hover {
  text-decoration: none;
  color: inherit;
}

.fullpagecontainerdark {
  position: absolute;
  background-color: #242424;
  width: 100%;
  height: 100%;
}

.fullpagecontainerlight {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}

.blankcompany {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #242424;
  color: #edf0f1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.blankpage {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 244, 255);
}

.loginPageWrapper {
  display: flex;
  justify-content: space-around;
}

.loginform {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #242424;
  text-decoration: none;
  color: #008899;
}

.loginform label {
  display: block;
  margin-top: 10px;
  padding: 2px;
  color: #242424;
}

.loginform input {
  display: block;
  padding: 6px;
  margin: 2px;
}

.loginform .textinput {
  border: 0;
  border-radius: 10px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  width: 300px;
  height: 40px;
}

.loginform .button {
  margin-top: 10px;
  text-align: center;
  background-color: rgb(127, 62, 143);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 140px;
  height: 30px;
  font-size: 16px;
}

.loginform .submitLine {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
}

.loginform .error {
  color: red;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 2%;
  background-color: #242424;
  color: #edf0f1;
  height: 50px;
  font-size: 18px;
}

.header__subtitle {
  background-color:  #edf0f1;
  color: #242424;
  width: 150px;
  text-align: center;
  border-radius: 15px;
  vertical-align: center;
}

.header__account {
  display: flex;
  cursor: pointer;
  vertical-align: center;
  justify-items: right;
  align-content: right;
  justify-content: flex-end;
  width: 150px;
}

.header__account__name {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
  vertical-align: center;
}

.header__account__navlinks {
  display: none;
  color: white;
  background-color: #242424;
  text-decoration: none;
  position: absolute;
  right: 0px;
  top: 50px;
  padding: 5px;
  align-content: center;
  list-style: none;
  width: 200px;
  max-height: 400px;
  justify-content: space-evenly;
  flex-direction: column;
  z-index: 9999;
}

.header__account__navlinks ul li {
  height: 20px;
  margin-bottom: 5px;
}
.header__account__navlinks ul li a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  padding: 0px 20px;
  transition: all 0.3s ease 0s;
}

ul {
  list-style: none;
}
ul li a:hover {
  color: #008899;
}

button {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  background-color: rgb(127, 62, 143);
  color: #edf0f1;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  max-width: 100px;
  height: 30px;
}

.logo {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #edf0f1;
  text-decoration: none;
  background-color: #242424;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.logobox {
  width: 65px;
}

.logo .company {
  background-color: #242424;
  justify-content: left;
}


.blockdisplaytoggle {
  display: block;
}
.flexdisplaytoggle {
  display: flex;
}
.nodisplay {
  display: none;
}

.mainmenu {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #242424;
  margin: 20px;
  max-width: 400px;
}

.mainmenu ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mainmenu ul li {
  margin: 20px;
  text-decoration: none;
}

.mainmenu ul li a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.mainmenuicon {
  width: 70px;
  text-align: center;
}

.topMenu {
  clear: both;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #242424;
  margin-top: 10px;
  justify-content: space-evenly;
  justify-items: left;
}

.topMenu ul {
  display: flex;
  border-bottom: 1px solid gray;
  height: 30px;
  justify-items: left;
  justify-content: left;
  width: auto;
  overflow: auto;
  overflow-x: scroll;
  overflow-y: scroll; /* overflow-y: hidden; */
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge, hide scrollbar but keep functionality */
  scrollbar-width: none;  /* Firefox hide scrollbar but keep functionality */
}

/* Hide scrollbar for Chrome, Safari and Opera, but keep the functionality */
.topMenu ul::-webkit-scrollbar {
  display: none;
}

.topMenu ul li:first-child {
  margin-left: 5px;
}
.topMenu ul li:last-child {
  margin-right: 5px;
}

.topMenu ul li {
  margin-right: 7px;
  margin-left: 7px;
  list-style: none;
  padding-bottom: 5px;
  height: 30px;
}

.topMenu a:link, a:visited, a:active {
  color: inherit;
  text-decoration: none;
}

.topMenu ul li a {
  transition: all 0.3s ease 0s;
}

.topMenu__active {
  border-bottom: 3px solid rgb(0, 161, 22);
}

.filterMenu {
  clear: both;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #242424;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 400px;
}

.filterMenu ul {
  display: flex;
  padding-left: 10px;
  justify-items: left;
  justify-content: left;
  list-style: none;
}

.filterMenu ul li {
  margin-right: 3px;
  list-style: none;
  height: 25px;
}

.filterButton {
  font-family: "Roboto", sans-serif;
  background-color: rgb(244, 244, 255);
  color: #242424;
  border: 1px solid rgba(128, 128, 128, 0.753);
  border-radius: 20px;
  cursor: pointer;
  width: auto;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;
}

.filterButton a, a:link, a:visited {
  text-decoration: none;
}

.filterButton__selected {
  background-color: rgb(200, 230, 217);
}

.filterSelect {
  margin-left: 10px;
  height: 25px;
}

.farmSelect {
  background-color: #242424;
  color: #edf0f1;
  height: 50px;
  font-size: 0.83em;
  font-weight: bold;
  border: 0;
}

.content {
  display: block;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.content p {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}

.printheader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.simpletable {
  width: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 0px;
  border-spacing: 0px;
}

.simpletable thead tr th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.simpletable tbody tr td {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.content.print {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.content.palletSticker {
  font-family: "Roboto", sans-serif;
  font-size: 48;
}

.headsumcontainer {
  width: auto;
  max-width: 400px;
  overflow-x:visible;
  overflow-y:visible; /* overflow-y: hidden; */
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border: 1px solid green;
  background-color: white;
  padding: 10px;
}

.headsumcontainer a {
  color: blue;
  font-weight: bolder;
}


.headsumcontainer table tbody tr td.incomplete {
  color: crimson;
  font-weight: bolder;
}

.headsumcontainer table tbody tr td.complete {
  color:#008899;
  font-weight: bolder;
}

.headsumcontainer::-webkit-scrollbar {
  display: none;
}

.headsumcontainer table {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  width: 100%;
}

.headsumcontainer table tbody tr {
  margin: 20px;
  padding: 10px;
  height: 20px;
}


.hscrollcontainer {
  width: auto;
  overflow-x: scroll;
  overflow-y: scroll; /* overflow-y: hidden; */
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.hscrollcontainer::-webkit-scrollbar {
  display: none;
}

.sticky {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
}

.hscrollcontainer p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  max-width: 800px;
}

.hscrollcontainer.width300 table {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    width: 300px;
}

.hscrollcontainer table {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  width: 1000px;
}

.hscrollcontainer table thead tr {
  background-color: #d7e0e9;
}

.hscrollcontainer table thead tr th {
  background-color: #d7e0e9;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  font-weight: normal;
  height: 20px;
}

.hscrollcontainer table tr:nth-child(even) {
  /* background-color: #F0F8FF; */
  background-color: #dfedf8;
}

.hscrollcontainer table tr td {
  height: 20px;
}

.actionButton {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
  background-color: rgb(127, 62, 143);
  color: #ffffff;
  border: 1px solid rgba(128, 128, 128, 0.753);
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.actionButton a, a:link, a:visited {
  text-decoration: none;
}

.inputForm {
  font-family: "Roboto", sans-serif;
  /*border: 1px solid black;*/
  max-width: 500px;
  margin: 20px;
}

.inputForm select {
  width: 100%;
  max-width: 200px;
}

.inputForm .header {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 3px;
  border-bottom: 1px solid gray;
}
.inputForm .header li {
  display: inline-flex;
  align-items: center;
}

.inputForm label p {
  display: inline-flex;
}

.inputForm label {
  display: block;
  margin-top: 10px;
  padding: 2px;
  color: #242424;
}

.inputForm label.nodisplay {
  display: none;
}

.inputForm div.nodisplay {
  display: none;
}

.inputForm input {
  display: inline-block;
  padding: 6px;
  margin: 2px
}

.inputForm label p.vertalign.link {
  display: inline-block;
  padding: 6px;
  margin: 2px;
  color: blue;
  cursor: pointer;
}

.inputForm select {
  display: block;
  padding: 6px;
  margin: 2px;
}

.inputForm .textareainput {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 6px;
}

.inputForm pre {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 16px;
  padding-left: 20px;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}

.inputForm .textinput {
  border: 0;
  border-radius: 10px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  width: 100%;
  height: 35px;
}

.inputform .file {
  width: 100%;
  height: 35px;
}

.inputForm .mergedLine {
  display: flex;
  justify-content: space-between;
}
.inputForm .mergedLine label {
  align-self: flex-end;
}

.inputForm .submitLine {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
}

.inputForm .error {
  color: red;
}

.inputForm .button {
  text-align: center;
  background-color: rgb(127, 62, 143);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 140px;
  height: 30px;
  font-size: 16px;
}

.inputForm button {
  font-family: "Roboto", sans-serif;
  text-align: center;
  background-color: rgb(127, 62, 143);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 140px;
  height: 30px;
  font-size: 16px;
}

.inputForm button:disabled,button[disabled] {
  font-family: "Roboto", sans-serif;
  /* <input id="nmcThingworxSubmitButton" disabled={true} type="submit" className="button" name="submitForm" value="Continue" /> */
  text-align: center;
  background-color: rgba(0, 161, 21, 0.418);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  cursor: text;
  width: 140px;
  height: 30px;
  font-size: 16px;
}

span.positive {
  color:green;
  font-weight: bolder;
}

span.negative {
  color:red;
  font-weight: bolder;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

span {
  display: inline-block;
  vertical-align: middle;
}

.backButton {
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.programList {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.programList .programListItem {
  margin-bottom: 20px;
}

.programHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  font-size: 16px;
}

.programBody {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding-top: 5px;
}

.programBody .container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.programBody .editLine {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.contentSubtitle {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.listRow {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: scroll; /* overflow-y: hidden; */
  border-bottom: 1px solid green;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.listItemInfo {
  display: flex;
  justify-content: flex-start;
  max-width: 70%;
  width: 100%;
  list-style: none;
  border: 0px solid gray;
}

.listItemInfo li {
  border: 0px solid red;
  width: 100px;
  overflow-wrap: anywhere;
}

.listItemActions {
  display: flex;
  justify-content: space-around;
  list-style: none;
  width: 100%;
}

.listItemActions li {

}


.group_for_checkbox {
  display: flex;
  vertical-align: middle;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
}
.group_for_checkbox label {
  margin-top: 0;
  margin-right: 10px;
}

.valve_checkbox {
  height: 20px;
  width: 20px;
}

@media screen {
  .printonly {
    display: none;
  }
}
@media print {
  .printonly {
    display: block;
  }
}

@media screen and (max-width:768px) {
  .header__account__name {
    display: none;
  }

  .hscrollcontainer p {
    width: 100%;
  }

  .hscrollcontainer table {
    width: 100%;
  }

  .hscrollcontainer table tbody tr td {
    height: 25px;
    padding-right: 3px;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
  }
}

.loadingOverlay {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.loadingOverlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

.loadingOverlay:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loadingOverlay:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 3px solid rgba(33, 150, 243, 1.0);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
